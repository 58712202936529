import React, { useState, useEffect } from 'react'
import Footer from 'components/layout/footer/index'
import Header from './header'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import setCookie from 'utils/setCookie'

export default function Layout({ children }) {
  const location = useLocation()
  const queriedParameters = queryString.parse(location.search)
  const {
    utm_source,
    utm_campaign,
    utm_agid,
    utm_term,
    creative,
    device,
    placement,
    utm_medium,
  } = queriedParameters
  console.log(utm_source + ' ' + utm_campaign)
  setCookie('utm_source', utm_source, 365)
  setCookie('utm_campaign', utm_campaign, 365)
  setCookie('utm_agid', utm_agid, 365)
  setCookie('utm_term', utm_term, 365)
  setCookie('creative', creative, 365)
  setCookie('device', device, 365)
  setCookie('placement', placement, 365)
  setCookie('utm_medium', utm_medium, 365)

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    setScrolled(true)
  }
  return (
    <React.Fragment>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  )
}
