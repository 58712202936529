export const INDUSTRIES = [
  { data: "Automotive", link: "/industries/automotive" },
  { data: "Banking & Finance", link: "/industries/banking-finance" },
  { data: "Healthcare", link: "/industries/healthcare" },
  { data: "Gaming", link: "/industries/gaming" },
  { data: "High-Tech", link: "/industries/technology" },
  { data: "Military", link: "/industries/military" },
  { data: "Government", link: "/industries/government" },
  { data: "Luxury Brands", link: "/industries/luxury-brands" },
  { data: "Legal", link: "/industries/legal-services" },
];

export const RESOURCES = [
  { data: "Blog for Translators", link: "/translator-hub.php" },
  { data: "Blog for Customers", link: "/business-center.php" },
  { data: "Tomedes Insights", link: "/tomedes-insider.php" },
  { data: "Translation News", link: "/translation-industry.php" },
  { data: "Technology Tools", link: "/translation-technology" },
  { data: "Supported file formats", link: "/supported-file-formats" },
  { data: "Summary Tool", link: "/summary-tool" },
  { data: "Word Count Ratio", link: "/word-count-ratio-tool" },
  { data: "Careers", link: "/careers" },
  { data: "Coronavirus", link: "/crisis-communication-center" },
];

export const COMPLIMENTARYSERVICES = [{ link: "Transcription" }, { link: "Voice-over" }, { link: "Closed Captioning" }, { link: "Content Writing" }, { link: "Desktop Publishing" }];

export const locations = [
  { location: "Atlanta", link: "/locations/atlanta-translation-services/" },
  { location: "Boston", link: "/locations/boston-translation-services/" },
  { location: "Chicago", link: "/locations/chicago-translation-services/" },
  { location: "Dallas", link: "/locations/dallas-translation-services/" },
  { location: "Denver", link: "/locations/denver-translation-services/" },
  { location: "Houston", link: "/locations/houston-translation-services/" },
  {
    location: "Los Angeles",
    link: "/locations/los-angeles-translation-services/",
  },
  { location: "Miami", link: "/locations/miami-translation-services/" },
  { location: "London", link: "/locations/london-translation-services/" },
  {
    location: "Manchester ",
    link: "/locations/manchester-translation-services/",
  },
  { location: "Melbourne", link: "/locations/melbourne-translation-services/" },
  { location: "NYC", link: "/locations/nyc-translation-services/" },
  {
    location: "Philadelphia",
    link: "/locations/philadelphia-translation-services/",
  },
  { location: "San Francisco ", link: "/locations/san-francisco-translation-services/" },
  { location: "Sydney", link: "/locations/sydney-translation-services/" },
  { location: "Toronto", link: "/locations/toronto-translation-services/" },
  { location: "San Diego", link: "/locations/san-diego-translation-services/" },
  { location: "Seattle ", link: "/locations/seattle-translation-services/" },
  { location: "Ottawa", link: "/locations/ottawa-translation-services/" },
  {
    location: "Washington DC",
    link: "/locations/washington-dc-translation-services/",
  },
];

export const termCondition = [ { link: "תנאי שימוש ", url: "https://www.tomedes.com/user-agreement.php" }, { link: "מטרות וסטנדרטים", url:"https://www.tomedes.com/our-translation-company.php" }, { link: "פרטיות", url: "https://www.tomedes.com/privacy.php" }];

export const AudioVisual = [
  { data: "Video translation", link: "/video-translation" },
  { data: "Audio translation", link: "/audio-translation" },
  { data: "Multilingual Transcription", link: "/transcription-services" },
 
];
