import LogoSvg from "assets/svg/logo";
import React from "react";

export default function Header() {
  return (
    <>
      <div className="bg-[#000000] flex items-start sm:justify-between pl-4 sm:px-[4rem] sm:flex-row flex-col min-h-[80px] sm:items-center  py-4 sm:py-5 fixed z-[100] w-full">
        <LogoSvg />
        <div className="flex sm:flex-row flex-col pt-4 sm:pt-0">
          <div className="sm:flex hidden">
            <p className=" font-sans text-[13px] sm:text-[22px] text-[#E6E6E6]">
            <a href="mailto:support@tomedes.com">tlv@tomedes.com</a> &nbsp;
            </p>
          </div>
          <div className="sm:pt-0 pt-2 sm:flex hidden">
            <p className="font-sans text-[13px] sm:text-[22px] text-[#E6E6E6]">
              | <a href="tel:+19852390142">IL: +972 72 2200700</a>
                {/* <span>&nbsp;|&nbsp;</span> */}
                {/* <a href="tel:+441615096140">UK: +44 (0)16 1509 6140</a> */}
            </p>
          </div>
          <div className="sm:pt-0 pt-2 sm:hidden flex">
            <p className="font-sans text-[13px] sm:text-[22px] text-[#E6E6E6]">
            <a href="mailto:support@tomedes.com">tlv@tomedes.com</a>
              <span>&nbsp;|&nbsp;</span>
              <a href="tel:+19852390142">IL: +972 72 2200700</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
