import { termCondition } from "components/layout/footer/FooterData";
import { Link } from "gatsby";
import React from "react";

function FooterCopyRight({whatsappBot}) {
  return (
    <>
      <div className="flex justify-evenly bg-black text-drakGray text-sm p-6 flex-wrap font-opensans align-middle">
        <p className="pl-4 pb-4 lg:pb-0 lg:pl-0 text-rtl"> © זכויות יוצרים  2007 - 2022 </p>
        <div className="col-start-3 col-end-4 pl-4 lg:pb-0 lg:pl-0">
          {termCondition?.map(({ link, url }, index) => (
            <React.Fragment key={index}>
              <Link to={url} className="cursor-pointer hover:text-orange transition-colors duration-300 text-rtl">
                {link}
              </Link>
              <span className="mx-2 last:hidden">|</span>
            </React.Fragment>
          ))}
        </div>
      </div>
      {whatsappBot ? whatsappBot :
      <a href="https://api.whatsapp.com/send?phone=972722200700" className="whatsapp-float" target="_blank">
        <img src="https://tomedes.gumlet.io/frontend/images/location_new/whatsapp_bubble.svg" />
      </a>
      }
    </>
  );
}

export default FooterCopyRight;
